<template>
    <div class="pageContainer socialPractice">
        <div class="tabBox flexBetween">
            <div class="tabBar flexStart" style="margin-left: 0px;">
                <div :class="['flexStart tabItem', activeTabIndex == 0 ? 'activeTab' : '']" @click="changeTab(0)">
                    <i class="el-icon-tickets"></i><span style="margin-left: 5px;">社会实践列表</span>
                </div>
                <div :class="['flexStart tabItem', activeTabIndex == 1 ? 'activeTab' : '']" @click="changeTab(1)">
                    <i class="el-icon-setting"></i><span style="margin-left: 5px;">指派学生填写</span>
                </div>
            </div>
        </div>
        <div v-if="activeTabIndex == 0" class="tabContent flexCloumn">
            <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
                <div class="flexStart flex_1" style="flex-wrap:wrap;">

                    <span>选择组织：</span>
                    <tchtree v-model="searchForm.class_ids" @change="searchData"></tchtree>

                    <span v-if="$store.state.pmid == 215">届次：</span>
                    <el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
                        placeholder="请选择" style="max-width:200px;" @change="searchData">
                        <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input placeholder="请输入学生姓名" v-model="searchForm.keyword" size="small" class="input-with-select ml10"
                        style="width:200px" clearable>
                    </el-input>

                    <el-select v-model="searchForm.sp_status" clearable placeholder="请选择" size="small"
                        style="width:130px;margin-left:10px">
                        <el-option label="全部" value></el-option>
                        <el-option label="待审核" :value="0"></el-option>
                        <el-option label="审核通过" :value="1"></el-option>
                        <el-option label="审核拒绝" :value="2"></el-option>
                    </el-select>

                    <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                        @click="searchData">搜索</el-button>
                </div>
                <div class="flexStart"
                    style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
                    <el-button icon="el-icon-download" size="small" @click="exportXls" type="primary" plain>导出未填写学生名单</el-button>
                    <el-button icon="el-icon-download" size="small" @click="exportPdfs" type="primary">导出社会实践表</el-button>

                    <el-button v-if="false && $store.state.config.sync_data" icon="el-icon-refresh" size="small"
                        type="primary" @click="tongbuJava">迁移老版本</el-button>
                </div>

            </el-row>
            <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
                style="width:fit-content;width:100%;" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center" />
                <el-table-column type="index" label="序号" width="55px" align="center" />
                <el-table-column label="学号" prop="stu_code" width="135" align="center" />
                <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center" />
                <el-table-column label="专业" prop="zy_name" show-overflow-tooltip width="150px" align="center" />
                <el-table-column label="班级" prop="class_name" show-overflow-tooltip width="120px" align="center" />

                <el-table-column label="实习单位" prop="firm_name" show-overflow-tooltip width="200px" align="center" />
                <el-table-column label="实习时间" prop="sx_date" width="160" align="center" />

                <el-table-column label="审核状态" prop="sp_status" width="100px" align="center">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.sp_status == 0" type="primary">待审核</el-tag>
                        <el-tag size="mini" v-if="scope.row.sp_status == 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.sp_status == 2" type="danger">审核拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="审核人" prop="sp_username" show-overflow-tooltip width="120px" align="center" />

                <el-table-column label="审核时间" prop="sp_time" show-overflow-tooltip width="200px" align="center" />
                <el-table-column label="审核评语" prop="sp_word" show-overflow-tooltip min-width="160" align="center" />


                <el-table-column fixed="right" label="操作" width="220" align="center">
                    <template slot-scope="scope">
                        <el-tag class="pointer" @click="handleView(scope.row)" type="primary" size="mini">查看</el-tag>

                        <el-tag class="pointer" v-if="scope.row.sp_status === 0" @click="handleView(scope.row, 'sp')"
                            type="success" size="mini" style="margin-left:10px;">审批</el-tag>

                        <el-tag class="pointer" v-if="scope.row.sp_status != 0" @click="handleRevoke(scope.row)"
                            type="warning" size="mini" style="margin-left:10px;">撤回审核</el-tag>

                        <el-tag class="pointer" v-if="scope.row.sp_status != 0" @click="exportPdf(scope.row)" type="success"
                            size="mini" style="margin-left:10px;">导出</el-tag>

                        <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info"
                            iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
                            <el-tag class="pointer ml10" slot="reference" size="mini" type="danger">删除</el-tag>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
                :current-page="page.current_page" :page-size="page.per_page" :total="page.count"
                layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
            </el-pagination>
        </div>
        <div v-if="activeTabIndex == 1" class="tabContent flexCloumn">
            <div style="color:#f56c6c;margin-bottom: 10px;">
                默认全部学生填写，若添加学生未生效，请管理员前往基础设置-系统设置-stu_assign_social_practice值设置为1</div>
            <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
                <div class="flexStart flex_1" style="flex-wrap:wrap;">

                    <span>选择组织：</span>
                    <tchtree v-model="searchFormAssign.class_ids" @change="searchDataAssign"></tchtree>

                    <span v-if="$store.state.pmid == 215">届次：</span>
                    <el-select v-if="$store.state.pmid == 215" clearable v-model="searchFormAssign.school_year" size="small"
                        placeholder="请选择" style="max-width:200px;" @change="searchDataAssign">
                        <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input placeholder="请输入学生姓名" v-model="searchFormAssign.keyword" size="small"
                        class="input-with-select ml10" style="width:200px" clearable>
                    </el-input>

                    <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                        @click="searchDataAssign">搜索</el-button>
                </div>
                <div class="flexStart"
                    style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
                    <el-button class="" size="small" type="primary" @click="addStu" icon="el-icon-plus"
                        style="margin-left: 10px;">添加学生</el-button>


                </div>
            </el-row>
            <el-table v-tableFit :data="tableDataAssign" row-key="id" height="100%" size="small" stripe border
                style="width:fit-content;width:100%;">
                <el-table-column type="index" label="序号" width="55px" align="center" />
                <el-table-column label="学号" prop="stu_code" width="135" align="center" />
                <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center" />
                <el-table-column label="专业" prop="zy_name" show-overflow-tooltip width="150px" align="center" />
                <el-table-column label="班级" prop="class_name" show-overflow-tooltip width="120px" align="center" />

                <el-table-column prop="sxqx" label="实习去向" width="100" align="center">
                    <template slot-scope="scope">
                        <span>{{ sxqxObj[scope.row.sxqx] || '-' }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="firm_name" label="实习企业" show-overflow-tooltip min-width="200" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.firm_name || '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="class_name" label="实习周期" width="240" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sxqx">{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</span>
                        <span v-if="!scope.row.sxqx">-</span>
                    </template>

                </el-table-column>


                <el-table-column fixed="right" label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info"
                            iconColor="red" @confirm="handleDelAssign(scope.row)" placement="left" title="是否确定移除？">
                            <el-tag class="pointer ml10" slot="reference" size="mini" type="danger">移除</el-tag>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handlePageChangeAssign" @size-change="handleSizeChangeAssign"
                :current-page="pageAssign.current_page" :page-size="pageAssign.per_page" :total="pageAssign.count"
                layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
            </el-pagination>

        </div>

        <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" @close="isRevoke = false"
            custom-class="cus_dialog" width="1000px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;"><span style="font-weight:700;">{{ formData.stu_name }}</span> 实践报告详情</span>
            </div>
            <div class="dialog_right">
                <div class="flexStart"
                    style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">学号</div>
                        <div class="cell flexStart flex_1">{{ formData.stu_code }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">姓名</div>
                        <div class="cell flexStart flex_1">{{ formData.stu_name }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">专业</div>
                        <div class="cell flexStart flex_1">{{ formData.zy_name }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">班级</div>
                        <div class="cell flexStart flex_1">{{ formData.class_name }}</div>
                    </div>


                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">实践单位</div>
                        <div class="cell flexStart flex_1">{{ formData.firm_name }}</div>
                    </div>

                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">实践时间</div>
                        <div class="cell flexStart flex_1">{{ formData.sx_date }}</div>
                    </div>

                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart" style="min-height: 40px;height: unset;">报告内容</div>
                        <div class="cell flexCloumn flex_1" style="height: unset;justify-content: center;min-height: 40px;">
                            {{ formData.content }}
                        </div>
                    </div>



                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">审核状态</div>
                        <div class="cell flexStart flex_1">
                            <el-tag size="mini" v-if="formData.sp_status == 0" style="color:#1289ff;">待审核</el-tag>
                            <el-tag size="mini" v-if="formData.sp_status == 1" style="color:#67c23a">审核通过</el-tag>
                            <el-tag size="mini" v-if="formData.sp_status == 2" style="color:#f56c6c">审核拒绝</el-tag>
                        </div>
                    </div>
                    <div v-if="formData.sp_status > 0" class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">审核人</div>
                        <div class="cell flexStart flex_1">{{ formData.sp_username }}</div>
                    </div>
                    <div v-if="formData.sp_status > 0" class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">审核时间</div>
                        <div class="cell flexStart flex_1">{{ formData.sp_time }}</div>
                    </div>
                    <div v-if="formData.sp_status > 0" class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart" style="min-height: 40px;height: unset;">审核评语</div>
                        <div class="cell flexCloumn flex_1" style="height: unset;justify-content: center;min-height: 40px;">
                            {{ formData.sp_word }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="" style="height:20px;"></div>
            <div slot="footer" v-if="isSp">
                <el-row class="flexStart" v-if="formData.sp_status == 0">
                    <el-input v-model.trim="spword" style="width: 100%" size="small" placeholder="请填写审核评语"></el-input>
                    <el-button class="ml10" size="small" type="danger" @click="submitForm(2)">拒绝</el-button>
                    <el-button size="small" type="success" @click="submitForm(1)">通过</el-button>
                </el-row>
                <el-button v-if="formData.sp_status != 0" size="small" type="warning"
                    @click="handleRevoke(formData)">撤回审核</el-button>
            </div>
        </el-dialog>

        <!-- 选人填写 未指派学生列表 -->
        <el-dialog ref="ruleForm" :visible.sync="dialogForm_addstu" :close-on-click-modal="false"  :modal-append-to-body="false" @close="isRevoke = false"
            custom-class="cus_dialog cus_dialogMax" width="1100px" style="z-index:3001!important;">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">未指派学生列表</span>
            </div>
            <div class="flexCloumn" style="height:100%;width:100%;">

                <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
                    <div class="flexStart flex_1" style="flex-wrap:wrap;">

                        <span>选择组织：</span>
                        <tchtree v-model="searchFormStu.class_ids" @change="searchDataStu" :modal="false"></tchtree>

                        <span v-if="$store.state.pmid == 215">届次：</span>
                        <el-select v-if="$store.state.pmid == 215" clearable v-model="searchFormStu.school_year"
                            size="small" placeholder="请选择" style="max-width:200px;" @change="searchDataStu">
                            <el-option v-for="item in schoolYears" :key="item.value" :label="item.title"
                                :value="item.value">
                            </el-option>
                        </el-select>

                        <el-input placeholder="请输入学生姓名" v-model="searchFormStu.keyword" size="small"
                            class="input-with-select ml10" style="width:200px" clearable>
                        </el-input>

                        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary" plain
                            @click="searchDataStu">搜索</el-button>
                    </div>
                    <!-- <div class="flexStart"
                        style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
                        <el-button class="" size="small" type="success" @click="overSlct" icon="el-icon-check"
                            style="margin-left: 10px;">确认添加学生</el-button>
                    </div> -->
                </el-row>

                <el-table v-tableFit :data="tableDataStu" height="100%" size="small" stripe border
                    style="width:fit-content;width:100%;" @selection-change="handleSelectionChangeStu">
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>
                    <el-table-column prop="stu_name" label="学生姓名" width="100" align="center"> </el-table-column>
                    <el-table-column prop="phone" label="性别" width="60" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.gender == 1">男</span>
                            <span v-if="scope.row.gender == 2">女</span>
                            <span v-if="!scope.row.gender">-</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="class_name" label="班级" width="130" align="center"></el-table-column>

                    <el-table-column prop="sxqx" label="实习去向" width="100" align="center">
                        <template slot-scope="scope">
                            <span>{{ sxqxObj[scope.row.sxqx] || '-' }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="firm_name" label="实习企业" show-overflow-tooltip min-width="200" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.firm_name || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="class_name" label="实习周期" width="240" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sxqx">{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</span>
                            <span v-if="!scope.row.sxqx">-</span>
                        </template>

                    </el-table-column>

                </el-table>
                <div class="" style="height:10px;"></div>

                <el-pagination @current-change="handlePageChangeStu" @size-change="handleSizeChangeStu"
                    :current-page="pageStu.current_page" :page-size="pageStu.per_page"
                    :page-sizes="[10, 20, 50, 100, 200, 500, 1000]" layout="total, prev, pager, next,sizes, jumper"
                    :total="pageStu.count">
                </el-pagination>
            </div>
            <div slot="footer">
                <el-button class="" size="" type="primary" @click="overSlct" icon="el-icon-check" :loading="loadingBtn"
                    style="margin-left: 10px;">确认添加学生</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";

export default {
    components: {
        tchtree
    },
    data() {
        return {
            activeTabIndex: 0,
            isRevoke: false,
            searchForm: {
                ktype: "stu_name",
                keyword: "",
                sxqx: "",
                sp_status: '',
                class_ids: ""
            },
            tableData: [],
            multipleSelection: [],
            dialogForm: false,
            formData: {},
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            spword: "",
            sxqxArr: [], sxqxObj: {},
            schoolYears: [],
            isSp: false,
            sign_img: '',
            searchFormAssign: { keyword: "", class_ids: "" },
            tableDataAssign: [],
            pageAssign: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },

            dialogForm_addstu: false,
            pageStu: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            tableDataStu: [],
            searchFormStu: { keyword: "", class_ids: "" },
            loadingBtn: false,
            multipleSelectionStu: [],

        }
    },
    watch: {
        dialogForm(n, o) {
            if (!n) {
                this.isSp = false
            }
        },
        loadingBtn(n, o) {
            let _this = this
            if (n) {
                setTimeout(() => {
                    _this.loadingBtn = false
                }, 3000)
            }
        },
    },
    mounted() {
        this.getComType()
        this.getSchoolYear().then(_ => {
            this.getList();
        })
        // this.changeTab(1)
    },

    methods: {
        changeTab(idx) {
            this.activeTabIndex = idx
            if (idx == 0) {
                this.$nextTick(() => {
                    this.getList()
                });
            }
            if (idx == 1) {
                this.$nextTick(() => {
                    this.searchDataAssign()
                });
            }
        },
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type: "'SHIXI_STATUS'",
                        used: 0
                    })
                    .then(res => {
                        let sxqxArr = [];
                        let sxqxObj = {};
                        for (let item of res.data) {

                            if (item.param_type == "SHIXI_STATUS") {
                                sxqxArr.push(item);
                                sxqxObj[item.param_value] = item.param_desc
                            }
                        }

                        this.sxqxArr = sxqxArr;
                        this.sxqxObj = sxqxObj;
                        console.log(sxqxObj)
                        resolve();
                    });
            });
        },
        getSchoolYear() {
            return new Promise((resolve, reject) => {
                if (this.$store.state.pmid == 215) {

                    let m = moment().format('M')
                    let nowYear = moment().year()
                    let schoolYears = []
                    if (m >= 5) {
                        nowYear++
                    }
                    for (let i = nowYear; i >= 2019; i--) {
                        let s = (i - 1) + "-" + i
                        let s_label = s + '学年(' + (i - 5) + '届)'
                        schoolYears.push({ title: s_label, value: s })
                    }
                    this.schoolYears = schoolYears
                    this.searchForm.school_year = schoolYears[0].value
                    this.searchFormAssign.school_year = schoolYears[0].value
                    this.searchFormStu.school_year = schoolYears[0].value

                }

                resolve()
            })
        },
        getList() {
            this.tableData = []
            let data = {};
            data.keyword = this.searchForm.keyword;
            data.sp_status = this.searchForm.sp_status;
            data.page = this.page.current_page;
            data.pagesize = this.page.per_page;
            data.class_ids = this.searchForm.class_ids
            data.school_year = this.searchForm.school_year

            this.$http.post("/api/stu_social_practice_tchrlist", data).then(res => {
                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },
        searchData() {
            this.page.current_page = 1;
            this.getList();
        },

        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },

        handleDel(row) {
            this.$http.post("/api/stu_social_practice_delete", { id: row.id }).then(res => {
                this.$message.success("删除成功");
                this.getList();
            });
        },
        handleView(row, type) {
            this.formData = JSON.parse(JSON.stringify(row))
            if (type == 'sp') {
                this.spword = ''
                this.isSp = true
            }
            console.log(532, row, type, this.isSp)

            this.dialogForm = true;
        },
        handleRevoke(row) {
            this.isRevoke = true
            this.$confirm(`是否确定撤回【${row.stu_name}】的审核？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$http.post("/api/stu_social_practice_revoke", { id: row.id }).then(res => {
                    this.$message({
                        type: "success",
                        message: "已撤回!"
                    });
                    this.getList();
                    this.dialogForm = false;
                });
            })
        },

        getMyqianming(e) {
            return new Promise(resolve => {
                this.$http.post("/api/get_sign_pic").then(res2 => {
                    if (res2 && res2.data && res2.data.handsign) {
                        this.sign_img = res2.data.handsign
                    }
                    resolve()
                }).catch(_ => {
                    resolve()
                })
            })

        },
        submitForm(st) {
            this.getMyqianming().then(rr => {
                if (!this.sign_img) {
                    this.$alert("您还没设置手写签名，请先在 小程序-我的 设置手写签名~")
                } else {
                    if (!this.spword.trim()) {
                        this.$message.error("请填写审批评语~");
                        return;
                    }
                    this.$http.post("/api/stu_social_practice_sp", {
                        id: this.formData.id,
                        sp_status: st,
                        sp_word: this.spword,
                        sp_sign: this.sign_img
                    }).then(res => {
                        this.$message({
                            type: "success",
                            message: "审核成功!"
                        });
                        this.getList();
                        this.dialogForm = false;
                    });
                }
            })
        },
        tongbuJava() {
            return
            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_stu_enter", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        exportXls() {
            this.$http.post('/api/export_socisal_practice_notfilledin_xls', {
                class_ids: this.searchForm.class_ids,
                school_year: this.searchForm.school_year
            }).then(res => {
                window.open(res.data.url, "_blank");
            });
        },
        exportPdfs() {
            let arr = [];
            if (!this.multipleSelection || this.multipleSelection.length == 0) {
                this.$message.error("请先选择学生");
            } else {
                for (let stu of this.multipleSelection) {
                    arr.push({
                        "id": stu.stu_id,
                        "name": stu.stu_name,
                        "class_name": stu.class_name
                    });
                }
                if (arr.length > 0) {
                    let params = {
                        "stuArr": arr,
                        "pmid": window.pmid,
                        "latest": 1
                    }
                    this.$http.post(this.$pdfUrl + "/exportStuSocialPractice", params).then(res => {
                        if (res.data.url) {
                            this.$message({
                                type: "success",
                                message: "生成成功!"
                            });
                            window.open(res.data.url, "_blank");   
                        } else {
                            let loadingInstance = this.$loading({
                                lock: true,
                                text: "正在导出请稍等...",
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.5)'
                            })
                            this.timer = setInterval(async () => {
                                let result = await this.$http.post(this.$pdfUrl + "/getFileUrl", {
                                    rkey: res.data.id,
                                    noloading: true
                                })
                                if (result.data.url) {
                                    clearInterval(this.timer)
                                    loadingInstance.close()
                                    window.open(result.data.url, "_blank")
                                }
                            }, 3000)
                        }
                    })
                }
            }
        },
        exportPdf(row) {
            let params = {
                "stuArr": [{
                    "id": row.stu_id,
                    "name": row.stu_name,
                    "class_name": row.class_name
                }],
                "pmid": window.pmid,
                "latest": 1
            }
            // export_zijian
            this.$http.post(this.$pdfUrl + "/exportStuSocialPractice", params).then(res => {
                this.$message({
                    type: 'success',
                    message: '生成成功!'
                });
                window.open(res.data.url, "_blank")
            })
        },

        // activeTabIndex==1
        getAssignStuList() {
            let data = {};
            data.keyword = this.searchFormAssign.keyword;
            data.page = this.pageAssign.current_page;
            data.pagesize = this.pageAssign.per_page;
            data.class_ids = this.searchFormAssign.class_ids
            data.school_year = this.searchFormAssign.school_year
            this.$http.post("/api/stu_social_practice_assign_stu_list", data).then(res => {
                this.tableDataAssign = res.data.data;
                this.pageAssign = res.data.page;
            });
        },

        searchDataAssign() {
            this.pageAssign.current_page = 1;
            this.getAssignStuList();
        },
        handleDelAssign(row) {
            this.$http.post("/api/stu_social_practice_assign_stu_delete", { id: row.stu_id }).then(res => {
                this.$message.success("移除成功");
                this.getAssignStuList();
            });
        },

        handlePageChangeAssign(page) {
            this.pageAssign.current_page = page;
            this.getAssignStuList();
        },
        handleSizeChangeAssign(val) {
            this.pageAssign.current_page = 1;
            this.pageAssign.per_page = val;
            this.getAssignStuList()
        },
        addStu() {
            this.getRemainingStuList()
            this.dialogForm_addstu = true
        },
        getRemainingStuList() {
            let data = {};
            data.keyword = this.searchFormStu.keyword;
            data.page = this.pageStu.current_page;
            data.pagesize = this.pageStu.per_page;
            data.class_ids = this.searchFormStu.class_ids
            data.school_year = this.searchFormStu.school_year

            this.$http.post("/api/stu_social_practice_remaining_stu_list", data).then(res => {
                this.tableDataStu = res.data.data;
                this.pageStu = res.data.page;
            });
        },
        handleSelectionChangeStu(val) {
            this.multipleSelectionStu = val;
        },
        handlePageChangeStu(page) {
            this.pageStu.current_page = page;
            this.getRemainingStuList();
        },
        handleSizeChangeStu(val) {
            this.pageStu.current_page = 1;
            this.pageStu.per_page = val;
            this.getRemainingStuList()
        },
        searchDataStu() {
            this.pageStu.current_page = 1;
            this.getRemainingStuList();
        },
        overSlct() {
            this.loadingBtn = true
            let arr = []
            if (!this.multipleSelectionStu || this.multipleSelectionStu.length == 0) {
                this.$message.error("请先选择学生");
            } else {
                for (let stu of this.multipleSelectionStu) {
                    arr.push(stu.id);
                }
                this.$http.post("/api/stu_social_practice_assign_stu_add", { stu_ids: arr.join(",") }).then(res => {
                    this.loadingBtn = false
                    this.$message({
                        type: "success",
                        message: "添加成功!"
                    });
                    this.multipleSelectionStu = []
                    this.searchDataStu()
                    // this.dialogForm_addstu = false
                    this.changeTab(1)
                });
            }
        },

    }
}
</script>
<style type="text/less" lang="less">
.socialPractice {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 0;


    .tabBox {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #98b9fc;

        .tabBar {
            height: 100%;
            align-items: end;

            .tabItem {
                height: 30px;
                border: 1px solid #3f4bda;
                margin-left: 15px;
                padding: 0 5px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                margin-bottom: -1px;
                cursor: pointer;
            }

            .activeTab {
                background-color: #2469f3;
                border: 0;
                color: #fff;
            }

            .activeTabTgt {
                background-color: #3e4ddb;
                border: 0;
                color: #fff;
            }
        }
    }

    .tabContent {
        padding-top: 20px;
        height: calc(100% - 40px);
    }


}
</style>
